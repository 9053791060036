/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 //@import '~flexlayout-react/style/gray.css';

$color-border-light: #f7f7fa;
$color-border-dark: #d7d7deeb;

$color_1: #eaeaee;
$color_2: #dadae2;
// $color_3: #d7d7de;
// $color_4: #c7c7d0;
$color_5: #b9b9c2;

$font-size: $font-size-small;
// font-size: 13.5px;

 @mixin absoluteFill {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.flexlayout {

    &__layout {
        @include absoluteFill;
        overflow: hidden;
    }

    &__splitter {
        background-color: $color-resize-bar;

        @media (hover: hover) {
            &:hover {
                background-color: $color-resize-bar-hover;
            }
        }
        &_border {
            z-index: 10;
        }

        &_drag {
            z-index: 1000;
            border-radius: 5px;
            background-color: $color_5;
        }
    }

    &__outline_rect {
        position: absolute;
        cursor: move;
        box-sizing: border-box;
        border: 2px solid $color-frame-drag;
        box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        z-index: 1000;

        &_edge {
            cursor: move;
            border: 2px solid green;
            box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            z-index: 1000;
            box-sizing: border-box;
        }
    }

    &__edge_rect {
        position: absolute;
        z-index: 1000;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background-color: gray;
    }

    &__drag_rect {
        position: absolute;
        cursor: move;
        color: $color-dark;
        background-color: $color_1;
        border: 2px solid $color-frame-borders;
        box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        z-index: 1000;
        box-sizing: border-box;
        opacity: 0.9;
        text-align: center;

        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        padding: 10px;
        word-wrap: break-word;
        font-size: $font-size;
        font-weight: 600;
    }

    &__tabset {
        overflow: hidden;
        background-color: $color-frames-tabset-bg;
        box-sizing: border-box;
        font-size: $font-size;
        font-family: $font-family-base;

        &_header {
            // tabset header bar
            position: absolute;
            display: flex;
            align-items: center;
            left: 0;
            right: 0;
            padding: 3px 3px 3px 5px;
            box-sizing: border-box;
            border-bottom: 1px solid $color-frame-borders;
            color: $color-dark;
            background-color: $color_1;
            box-shadow: inset 0 0 3px 0 rgba(136, 136, 136, 0.54);

            &_content {
                flex-grow: 1;
            }
        }

        &_tabbar {
            &_outer {
                // tabset tabbar outer
                box-sizing: border-box;
                background-color: $color-frame-borders-bg;
            //    background-image: linear-gradient(90deg, $color-border-light 0%, $color-border-dark 100%);

                position: absolute;
                left: 0;
                right: 0;
                overflow: hidden;
                display: flex;
            }

            &_outer_top {
                opacity: 0.4;
                border-bottom: 1px solid $color-frame-borders;
            }

            &_outer_bottom {
                border-top: 1px solid $color-frame-borders;
            }

            &_inner {
                // tabset tabbar inner
                position: relative;
                box-sizing: border-box;
                display: flex;
                flex-grow: 1;
                overflow: hidden;

                &_tab_container {
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 10000px;
                    
                    height: $frame-container-height;
                    // padding: 2px;

                    &_top {
                //        border-top: 2px solid transparent; // if you change this, update the _sizer above
                    }
                    &_bottom {
                        border-bottom: 2px solid transparent;
                    }
                }
            }
        }

        &-selected {
            opacity: 1;
        }

        &-maximized {
            background-color: $color-frames-tabset-bg;
        }
    }

    &__tab {
        overflow: auto;
        position: absolute;
        box-sizing: border-box;
        color: $color-dark;
        background-color: $color-frames-tab-bg;

        &_button {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 29px;
            padding: 0px 6px 0px 8px; // if you change top or bottom update the tabset_sizer above
            margin: 0 3px;
            box-sizing: border-box;

            white-space: nowrap;
            background-color: $color-frame-button-bg;
            border: 1px solid $color-frame-button-border;
            border-radius: $border-radius;
            font-weight: 600;
            letter-spacing: .25px;

            &.search,
            &.clipboard {

                padding: 0 8px;
            }

            &.rs-icon,
            &.fa,
            &.iconmoon {

                &::before {
                    margin-right: 5px;
                    font-size: 14px;
                }

                .flexlayout__tab_button_content {
                    font-family: $font-family-base;
                    font-size: $font-size;
                    line-height: 1.2;
                }
            }

            &--selected {
                background-color: $color-frame-buttonSelected-bg;
                border-color: $color-frame-buttonSelected-bg;
                color: $color-white;

                &:hover,
                &:focus-within,
                &:focus,
                &:active {
                    background-color: $color-frame-buttonSelected-bg;
                    border-color: $color-frame-buttonSelected-bg;
                    color: $color-white;
                }
            }

            &--unselected {
                color: $color-frame-button;

                &:hover,
                &:focus-within,
                &:focus,
                &:active {
                    color: $color-frame-button-hover;
                    background-color: $color-frame-button-hover-bg;
                    border-color: $color-frame-button-hover-bg;
                }
            }

            @include frame-icons('tab');

        //   &_top {
            //   box-shadow: inset -2px 2px 5px 0px rgba(0, 0, 0, .15);
            //   border-top-left-radius: 3px;
            //   border-top-right-radius: 3px;
        //   }

            &_bottom {
            //    box-shadow: inset -2px 0px 5px rgba(0, 0, 0, 0.1);
            //    border-bottom-left-radius: 3px;
            //    border-bottom-right-radius: 3px;
            }

            &_leading {
                display: inline-block;
            }

            &_content {
                display: flex;
                align-items: center;
            }

            &_textbox {
                border: none;
                color: $color_2;
                background-color: $color-frame-buttonSelected-bg;
                border-bottom: 1px solid $color_2;
            }

            &_textbox:focus {
                outline: none;
            }

            &_trailing {
                display: flex;
                margin-left: 6px;
                visibility: hidden;
                background: none;
        //       min-width: 10px;
        //       min-height: 10px;

        //       background: transparent url("/assets/images/icons/close.png") no-repeat center;

                i {
                    color: inherit;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            @media (hover: hover) {
                &:hover &_trailing {
                    visibility: visible;
                    background: none;
                }
            }

            /* @media (pointer: coarse) {
                &_trailing {
                    min-width: 20px;
                    min-height: 20px;
                }
            }
            @media (hover: hover) {
                &:hover &_trailing {
                    background: transparent url("/assets/images/icons/close.png") no-repeat center;
                }
            }

            &--selected &_trailing {
                background: transparent url("/assets/images/icons/close.png") no-repeat center;
            } */

            &_overflow {
                
                border: none;
                font-size: 13px;
                font-weight: 600;

                height: $frame-button-height;

                background: $color-frame-button-bg;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                padding-right: 0;

                i {
                    font-size: 17px;
                }

                &:hover {
                    background: $color-frame-button-hover-bg;
                }
            }
        }

        &_toolbar_sticky_buttons_container {
            
            margin-left: 8px;

            .flexlayout__tab_toolbar_sticky_button {

                width: $frame-button-height;
                height: $frame-button-height;
                background-color: transparent;
                border-radius: $border-radius;
                border: none;
                outline: none;

                display: flex;
                align-items: center;
                justify-content: center;

                padding: 0;
                color: $color-frame-button;

                i {
                    font-size: 22px;
                }

                &:hover {
                    background-color: $color-frame-button-hover-bg;
                }
            }
        }
        
        &_toolbar {
            display: flex;
            align-items: center;
            padding: 0 5px;
            gap: 3px;

            &_button {
                min-width: $frame-button-height;
                height: $frame-button-height;
                background-color: $color-frame-button-bg;
                border: none;
                outline: none;
                border-radius: $border-radius;

                display: flex;
                align-items: center;
                justify-content: center;

                padding: 0;
                color: $color-frame-button;

            //   &-min {
            //       background: transparent url("/assets/images/icons/maximize.png") no-repeat center;
            //   }

               &-max {
               //    background: transparent url("/assets/images/icons/restore.png") no-repeat center;
               }

                &-float {
                    background: transparent url("/assets/images/icons/popout.png") no-repeat center;
                }

                i {
                    font-size: 22px;
                }

                &:hover,
                &:focus,
                &:focus-within {
                    background-color: $color-frame-button-hover-bg;
                }
            }

            .flexlayout__tab_toolbar_sticky_button {

                width: $frame-button-height;
                height: $frame-button-height;
                background-color: transparent;
                border-radius: $border-radius;
                border: none;
                outline: none;

                display: flex;
                align-items: center;
                justify-content: center;

                padding: 0;
                color: $color-frame-button;

                i {
                    font-size: 22px;
                }

                &:hover,
                &:focus,
                &:focus-within {
                    background-color: $color-frame-button-hover-bg;
                }
            }
        }

        &_floating {
            overflow: auto;
            position: absolute;
            box-sizing: border-box;
            color: $color-dark;
            background-color: $color-frames-tab-bg;
            display: flex;
            justify-content: center;
            align-items: center;

            &_inner {
                overflow: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            &_inner div {
                margin-bottom: 5px;
                text-align: center;
            }

            &_inner div a {
                color: $color-action;
            }
        }

        //style for frame inside frame (like Image Graph Authoring
        & .flexlayout__layout .flexlayout__tabset {

            .flexlayout__tabset_tabbar_outer.flexlayout__tabset_tabbar_outer_bottom {
                background-color: $color-tf-dashboard-bg;
            }

            .flexlayout__tabset_tabbar_inner_tab_container.flexlayout__tabset_tabbar_inner_tab_container_bottom {

                & .flexlayout__tab_button_bottom {

                    box-shadow: none;
                    border-radius: inherit;
                    background-color: $color-frame-button-bg;

                    &.flexlayout__tab_button--selected {

                        background-color: $color-frame-buttonSelected-bg;
                    }
                }
            }
        }
    }

    &__border {
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        font-family: $font-family-base;
        font-size: $font-size;

        &_top {
            border-bottom: 1px solid $color-frame-borders;
            align-items: center;
        }

        &_bottom {
            border-top: 1px solid $color-frame-borders;
            align-items: center;
            background-color: $color-frame-borders-bg;
        //    background-image: linear-gradient(90deg, $color-border-light 0%, $color-border-dark 100%);

        }

        &_left {
            border-right: 1px solid $color-frame-borders;
            align-content: center;
            flex-direction: column;
            background-color: $color-frame-borders-bg;
        //     background-image: linear-gradient($color-border-light,$color-border-dark);

        }

        &_right {
            border-left: 1px solid $color-frame-borders;
            align-content: center;
            flex-direction: column;
            background-color: $color-frame-borders-bg;
        //   background-image: linear-gradient($color-border-light, $color-border-dark);

        }

        &_inner {
            position: relative;
            box-sizing: border-box;
            display: flex;
            overflow: hidden;
            flex-grow: 1;

            &_tab_container {
                white-space: nowrap;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 10000px;

                height: $frame-container-height;
               // padding: 2px;

                &_right {
                    transform-origin: top left;
                    transform: rotate(90deg);
                }
                &_left {
                    flex-direction: row-reverse;
                    transform-origin: top right;
                    transform: rotate(-90deg);
                }
            }
        }

        &_button {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 29px;
            padding: 0px 6px 0px 8px;
            margin: 0 3px;
            box-sizing: border-box;

            white-space: nowrap;
            background-color: $color-frame-button-bg;
            border: 1px solid $color-frame-button-border;
            border-radius: $border-radius;
            font-weight: 600;
            letter-spacing: .25px;

            &.search,
            &.clipboard {

                padding: 0 8px;
            }

            &--selected {
                background-color: $color-frame-buttonSelected-bg;
                border-color: $color-frame-buttonSelected-bg;
                color: $color-white;

                &:hover,
                &:focus-within,
                &:focus,
                &:active {
                    background-color: $color-frame-buttonSelected-bg;
                    border-color: $color-frame-buttonSelected-bg;
                    color: $color-white;
                }
            }

            &--unselected {
                color: $color-frame-button;

                &:hover,
                &:focus-within,
                &:focus,
                &:active {
                    color: $color-frame-button-hover;
                    background: $color-frame-button-hover-bg;
                    border-color: $color-frame-button-hover-bg;
                }
            }

            @include frame-icons('border');

            &_leading {
                display: inline;
            }

            &_content {
                display: flex;
                align-items: center;
            }

            &_trailing {
                display: flex;
                margin-left: 6px;
                visibility: hidden;
            
                i {
                    color: inherit;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            @media (hover: hover) {
                &:hover &_trailing {
                    visibility: visible;
                    background: none;
                //   background: transparent url("/assets/images/icons/close.png") no-repeat center;
                }
            }

            /*  @media (pointer: coarse) {
                &_trailing {
                    min-width: 20px;
                    min-height: 20px;
                }
            }

            &--selected &_trailing {
                background: transparent url("/assets/images/icons/close.png") no-repeat center;
            } */
        }

        &_toolbar {
            display: flex;
            align-items: center;

            &_left,
            &_right {
                flex-direction: column;
                padding: 4px 0;
            }

            &_bottom {
                padding: 0 4px;
            }

            &_button {
                min-width: 20px;
                min-height: 20px;
                border: none;
                outline: none;

                   &-float {
                     background: transparent url("/assets/images/icons/popout.png") no-repeat center;
                   }

                &_overflow {
                    
                        border: none;
                        font-size: 13px;
                        font-weight: 600;

                        height: $frame-button-height;
                        background: $color-frame-button-bg;
                        
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;

                        padding-right: 0;

                        i {
                            font-size: 17px;
                        }

                        &:hover {
                            background: $color-frame-button-hover-bg;
                        }
                }

                &_overflow_top,
                &_overflow_bottom {
                 //   margin-left: 10px;
                }

                &_overflow_right,
                &_overflow_left {
                    padding-right: 0px;
                }
            }
        }
    } 

    &__popup_menu {

        &_container {

            border: 1px solid $color-border;
            border-radius: $border-radius;

            color: $dropdown-link-color;
            background: $color-white;

            position: absolute;
            z-index: 1000;
            max-height: 50%;
            min-width: 100px;
            overflow: auto;

            margin: 2px 0 0;
            padding: 8px 0;

        }

        &_item {
        color: $dropdown-link-color;
        font-size: $dropdown-font-size;
        padding: 9px 25px 9px 20px;
        clear: both;
        line-height: 1.42857;
        white-space: nowrap;

        cursor: pointer;
        }

        @media (hover: hover) {
            &_item:hover {
                background-color: $color-lighter;
            }
        }

    }

    &__floating_window {
        &_body {
            height: 100%;
        }

        &_content {
            @include absoluteFill;
        }

        &_tab {
            overflow: auto;
            @include absoluteFill;

            box-sizing: border-box;
            background-color: $color-body-bg;
            color: $color-dark;
        }
    }

    &__error_boundary_container {
        @include absoluteFill;
        display: flex;
        justify-content: center;
    }

    &__error_boundary_content {
        display: flex;
        align-items: center;
    }

    // These classes are used to measure the sizes for tab header bar, tab bar and border bars,
    // only the height is used, the padding should match the vertical spacing (padding & margins)
    // that have been used in the above classes

    &__tabset_sizer {
        padding-top: 5px; // tab_button has 3 padding top, tabset header inner has 2 border
        padding-bottom: 3px; // tab_button has 3 padding bottom
        font-size: $font-size;
    }

    &__tabset_header_sizer {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: $font-size;
    }

    &__border_sizer {
        padding-top: 6px;
        padding-bottom: 5px;
        font-size: $font-size;
    } 
}
 
