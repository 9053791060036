/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.help-page-container {

  font-size: 15.5px;

  .text-link-action {
    font-weight: normal;

    &:hover,
    &:focus, 
    &:focus-within {
      font-weight: normal;
    }
  }

  ul:not(.nav.nav-tabs):not(.pagination) > li > a {
    text-decoration: underline;
    color: $color-action;
  }

  .table>tbody>tr>td>a, .table>tbody>tr>td>span a {
    color: $color-action;
  }

  h1 {
    font-size: 3.5rem;
    margin: 0 0 1.5rem 0;
  }

  h2 {
    font-size:2.6rem;
    margin: 0 0 1.5rem 0;
  }

  h3 {
    font-size:2.2rem;
    margin: 0 0 1rem 0;
  }

  h4 {
    font-size:1.8rem;
    margin: 2rem 0 1rem 0;
  }

  h4.panel-title {
    margin: 0;
  }

  h5 {
    font-size: 1.7rem;
  }

  p, div, ul:not(.nav.nav-tabs), li, dt {
    line-height: 1.8;
  }

  li {
    margin-bottom: 7px;
  }

  .nav.nav-tabs>li {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.5;
  }

  ol li, ul:not(.nav.nav-tabs) li {
  //  margin-top: 8px;
  }

  dl, ol, ul:not(.nav.nav-tabs), pre, blockquote, .table {
    margin-bottom: 1.8rem;
  }

  hr {
    margin: 30px 0;
  }

  pre {
    padding: 20px;
  }

  .CodeExample--holder .CodeBlock--holder pre {
    margin-bottom: 0;
  }

  section:first-child {
   // margin-top: 5rem;
  }

  .CodeExample--holder {
    margin-top: 5px;
  }

  .Tree--leafNode .Tree--itemLink, 
  .Tree--leafNode a, 
  .Tree--treeNode .Tree--itemLink, 
  .Tree--treeNode a {
    color: $color-action;
    text-decoration: underline;
  }
} 

.rs-doc_2columns {

  display: flex;
  gap:15px;

  & > div {
      flex: 1;
  }

  @media screen and (max-width: 768px) {
      flex-direction: column;
  }
}


.page-help-tutorial {
  .inline-code {
    padding: 2px 4px;
    color: #d14;
    white-space: nowrap;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
  }

  .feature-row {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .feature-box {
    min-height: 200px;
    margin: 25px;
    border: 2px solid #aaa;
  }

/*   .feature-box-invisible {
    min-height: 200px;
    margin: 25px;
    border: none;
  } */

  .feature-box-image {
    margin-top: -40px;
    margin-bottom: 15px;
  }

  .feature-box-title {
    margin: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .feature-box-list {
    margin: 25px;
    line-height: 1.8em;
  }
}

.documentation {
  h3 {
    margin-top: $padding-base-vertical * 3;
    margin-bottom: $padding-base-vertical * 2;
  }

  &__example,
  &__intro {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1600px) {
      flex-direction: column;
    }

    &__description,
    &__demo {
      width: 49%;

      @media (max-width: 1600px) {
        width: auto;
      }
    }
  }
}
