/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


// ---------------------------------------------------------------
// Variables
// ---------------------------------------------------------------
// IMPORTANT: when overriding bootstrap variables DON'T use !default
// Using Sass !default is like adding an “unless this is already assigned” qualifier to your variable assignments.


/************************* FONTS ********************************/
$web-font-path: '~platform/styling/fonts.css';

/************************* COLORS ********************************/

/****************** MAIN SYSTEM COLORS *************************/
// Main color: for text and more
$color-dark: var(--color-dark, #242426);
$color-dark--op75: var(--color-dark--op75, rgba(36, 36, 38, 0.75));
$color-dark--op50: var(--color-dark--op50, rgba(36, 36, 38, 0.5));
$color-dark--op30: var(--color-dark--op30, rgba(36, 36, 38, 0.3));
$color-dark--op20: var(--color-dark--op20, rgba(36, 36, 38, 0.2));
$color-dark-darker: var(--color-dark-darker, #1c1c1e);

$color-secondary: var(--color-secondary, #525156);
$color-secondary-light: var(--color-secondary-light,#84838a);
$color-base: var(--color-base, #8F8F96);
$color-medium: var(--color-medium, #cfcfd8);
$color-light: var(--color-light, #e5e5ed);
$color-lighter-hover: var(--color-lighter-hover, #e7e7ed);
$color-lighter: var(--color-lighter, #f0f0f5);
$color-off-white: var(--color-off-white, #f5f5f8);
$color-white: #ffffff;

$color-action: var(--color-action,#396EFE);
$color-action-border: var(--color-action-border,#a3bcfe);
$color-action-light: var(--color-action-light,#527ff8);
$color-action--op60: var(--color-action--op60,rgba(57, 110, 254, 0.6));
$color-action--op30: var(--color-action--op30,rgba(57, 110, 254, 0.3));
$color-action--op10: var(--color-action--op10,rgba(57, 110, 254, 0.1));

$color-info-light: var(--color-info-light, #d3ddec);
$color-info-text: var(--color-info-text, #1f62af);
$color-info: var(--color-info, #3793ff); 
$color-info-border: var(--color-info-border, #bbcde6); 

$color-success-light: var(--color-success-light,#a7dace);
$color-success: var(--color-success, #1ba182);
$color-success-text: var(--color-success-text, #096651);
$color-success-darker: var(--color-success-darker, rgb(15, 112, 90));

$color-warning-light: var(--color-warning-light, #fff3cd);
$color-warning-text: var(--color-warning-text, #856404);
$color-warning: var(--color-warning, #f5c923);
$color-warning-darker: var(--color-warning-darker, #e3b71a);

$color-danger-light: var(--color-danger-light, #edb9bf);
$color-danger: var(--color-danger, #f4364e);
$color-danger-text: var(--color-danger-text, #970d20);
$color-danger-border: var(--color-danger-border, #e78d99);
$color-danger-darker: var(--color-danger-darker, #b01a2e);

/****************** CUSTOM COLORS FOR UI ELEMENTS **********************/
$color-border: var(--color-border,#E0E0E4);
$color-border-darker: var(--color-border-darker,#dadae2);

$color-box-shadow: var(--color-box-shadow, #dedee3);

$color-navbar: var(--color-navbar,#ffffff);
$color-navbar-btn-bg: var(--color-navbar-btn-bg,#3a3a3d);
$color-km-handle: var(--color-km-handle, $color-base);

// Background colors
$color-body-bg: var(--color-body-bg, #F7F7FA);
$color-navbar-bg: var(--color-navbar-bg, #242426);
$color-footer-bg: var(--color-footer-bg, #242426);
$color-modal-bg: var(--color-modal-bg, #F7F7FA);

$color-breadcrumb-bg: var(--color-breadcrumb-bg, #F7F7FA);

$color-tf-dashboard-bg: var(--color-tf-dashboard-bg, #F7F7FA);
$color-km-bg: var(--color-km-bg, #F7F7FA);
$color-narrative-bg: var(--color-narrative-bg, #F7F7FA);
$color-dashboardPage-bg: var(--color-dashboardPage-bg, #F7F7FA);
$color-resourcePage-bg: var(--color-resourcePage-bg, #F7F7FA);
$color-resourceTabs-bg: var(--color-resourceTabs-bg, #F7F7FA);

$color-tf-dashboardCard-bg: var(--color-tf-dashboardCard-bg, #ffffff);
$color-resource-popover-bg: var(--color-resource-popover-bg, #555458);
$color-popover-bg: var(--color-popover-bg, #ffffff);
$color-narrative-popover-bg: var(--color-narrative-popover-bg, #f7f7fa);

$color-alert-message-bg: var(--color-alert-message-bg, #d93348);

$color-set-management-bg: var(--color-set-management-bg, #f0f0f5);

$color-sidebar-bg: var(--color-sidebar-bg, #F7F7FA);
$color-sparlSidebar-bg: var(--color-sparlSidebar-bg, #F7F7FA);

$color-panel-bg: var(--color-panel-bg, #ffffff);
$color-panelHeading: var(--color-panelHeading, $color-dark);
$color-panelHeading-bg: var(--color-panelHeading-bg, $color-light);

$color-paginationHover-bg: var(--color-paginationHover-bg, #ecf0f1);

$color-codeBlock-bg: var(--color-codeBlock-bg, transparent);
$color-codeBlockMode-bg: var(--color-codeBlockMode-bg,  $color-lighter);
$color-codeBlockHolder-bg: var(--color-codeBlockHolder-bg, $color-lighter);

$color-configDoc-border: var(--color-configDoc-border, $color-border);
$color-configDocHeader-bg: var(--color-configDocHeader-bg, #ffffff);


// Frame system with flexLayout
$color-frames-tabset-bg: var(--color-frames-tabset-bg, #F7F7FA);
$color-frames-tab-bg: var(--color-frames-tab-bg, #F7F7FA);
$color-frame-borders:  var(--color-frame-borders, $color-border);
$color-frame-borders-bg:  var(--color-frame-borders-bg, #ffffff);
$color-frame-drag: var(--color-frame-drag, #242426);
$color-frame-button: var(--color-frame-button, $color-secondary); 
$color-frame-button-border: var(--color-frame-button-border,transparent); 
$color-frame-button-bg: var(--color-frame-button-bg,$color-light); 
$color-frame-buttonSelected-bg: var(--color-frame-buttonSelected-bg,#242426); 
$color-frame-button-hover: var(--color-frame-button-hover, $color-frame-button); 
$color-frame-button-hover-bg: var(--color-frame-button-hover-bg, $color-light); 
$frame-container-height: var(--frame-container-height, 36px); 
$frame-button-height: var(--frame-button-height, 29px); 

// resize splitter bar
$color-resize-bar: var(--color-resize-bar, $color-border);
$color-resize-bar-hover: var(--color-resize-bar-hover, #cfcfd5);

// CodeMirror
$color-codeMirror-bg: var(--color-codeMirror-bg, #ffffff);
$color-codeMirror-border: var(--color-codeMirror-border, #1d096e21);

// Icon colors
$color-icon-dark: var(--color-icon-dark, #525156);

// Buttons colors
$color-btn-default: var(--color-btn-default, $color-secondary);
$color-btn-default-bg: var(--color-btn-default-bg, #ffffff);
$color-btn-default-border: var(--color-btn-default-border, $color-border);
$color-btn-default-hover: var(--color-btn-default-hover, $color-secondary);
$color-btn-default-bg-hover: var(--color-btn-default-bg-hover, #ffffff);
$color-btn-default-bg-active: var(--color-btn-default-bg-active, $color-lighter);

$color-btn-selected: var(--color-btn-selected, #7b7979);

$color-btn-toolbar: var(--color-btn-toolbar, #65656d);
$color-btn-toolbar-bg: var(--color-btn-toolbar-bg, #ffffff);
$color-btn-toolbar-border: var(--color-btn-toolbar-border, $color-border);


// Card colors
$color-card-border: var(--color-card-border, #dedee3);
$color-card-icon-hover: var(--color-card-icon-hover, #dddde1);
$color-card-boxShadow: var(--color-card-boxShadow, #dedee3);

// Dashboard colors
$color-dashboard-km: var(--color-dashboard-km, #4f39a294); 
$color-dashboard-narrative: var(--color-dashboard-narrative, #6780add9); 
$color-dashboard-image: var(--color-dashboard-image, #729892); 

// Mirador colors
//$mirador-main-color: var(--mirador-main-color, #60626d);
$mirador-main-color-bg: var(--mirador-main-color-bg, $color-white);
$mirador-main-color: var(--mirador-main-color, $color-secondary);
$mirador-window-color-bg: var(--mirador-window-color-bg, $color-white);
$mirador-mainMenu-color: var(--mirador-mainMenu-color, $color-secondary);
$color-miradorViewer-bg: var(--color-miradorViewer-bg, $color-white);
$color-miradorSlot-bg: var(--color-miradorSlot-bg, $color-white);
//$color-miradorSlot-shadow: var(--color-miradorSlot-shadow,  #65656d);
//$color-miradorSlot-shadow-darker: var(--color-miradorSlot-shadow-darker, #545353);
//$color-imageView-shadow: var(--color-imageView-shadow, #80808063);
$color-imageIcons: var(--color-imageIcons, $color-icon-dark);
$color-miradorBottomPanel-bg: var(--color-miradorBottomPanel-bg, #e7e7ed75);
$color-miradorTooltipDark-bg: var(--color-miradorTooltipDark-bg, $color-secondary);

$mirador-topBar-color-bg: var(--mirador-topBar-color-bg, $color-white);
$mirador-dropdown-color-bg: var(--mirador-dropdown-color-bg, #ffffff);

//Scrollbars
$scrollbar-bg: var(--scrollbar-bg, #cfcfd4);
$scrollbar-track: var(--scrollbar-track, rgba(0, 0, 0, 0.089));

// Timeline
$color-timeline-border: var(--color-timeline-border, $color-border);
$color-timeline-text: var(--color-timeline-text, $color-dark);

// Entity: background and icon colors
$color-entity-bg-thing: var(--color-entity-bg-thing, #e0dee9);
$color-entity-bg-actor: var(--color-entity-bg-actor, #d0d9dd);
$color-entity-bg-type: var(--color-entity-bg-type, #e3e0d7);
$color-entity-bg-resource: var(--color-entity-bg-resource, #e5e9f0);

$color-entity-icon-thing: var(--color-entity-icon-thing, #9289b7);
$color-entity-icon-actor: var(--color-entity-icon-actor, #45626F);
$color-entity-icon-type: var(--color-entity-icon-type, #857C70);
$color-entity-icon-resource: var(--color-entity-icon-resource, #506B9B);


//  Bootstrap color variables override
$gray-base: #525156; //can't be css variable
$gray-darker: lighten($gray-base, 13.5%);
$gray-light: #b4bcc2; //can't be css variable
$gray-lighter: #ecf0f1; //can't be css variable

$brand-primary: #242426; //can't be css variable
$brand-success: #a7dace; //can't be css variable
$brand-info: #3793ff; //can't be css variable
$brand-warning: #f5c923; //can't be css variable
$brand-danger: #f4364e; //can't be css variable

$link-color: #3498db; // Global textual link color. //can't be css variable
$link-hover-color: darken($link-color, 10%);

$body-bg: $color-body-bg; //** Background color for `<body>`.
//$text-color: #242426; //** Global text color on `<body>`. //can't be css variable

/************************* TYPOGRAPHY ********************************/

//  Bootstrap variables override
$font-family-sans-serif: 'Source Sans Pro','Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-base: var(--font-family-base, $font-family-sans-serif);

$font-size-base: 14.5px; //can't be css variable
$font-size-xsmall: 12.5px; //can't be css variable
$font-size-small: 13.5px; //can't be css variable
$font-size-large: 15.5px; //can't be css variable

$icon-font-size: var(--icon-font-size, 18px);

$line-height-body: 1.2;
$line-height-base: 1.428571429;  //can't be css variable
$line-height-computed: floor(($font-size-base * $line-height-base));

// Custom variables
$font-size-body: var(--font-size-body, 14.5px);

//$merriweather: "Merriweather Sans", sans-serif;
//$sourceSans: "Source Sans Pro", sans-serif;
//$nunito: "Nunito", sans-serif;
$font-family-navbar-logo: var(--font-family-navbar-logo, "Source Sans Pro", sans-serif);


/************************* COMPONENTS ********************************/
//## Define common padding and border radius sizes and more.
$border-radius: var(--border-radius, 1.5px);

//  Bootstrap variables override
$padding-base-vertical: 10px;  //can't be css variable
$padding-base-horizontal: 12px; //can't be css variable

$border-radius-base: 0;
$border-radius-large: 0;


/************************* TABLES ***********************************/
//  Bootstrap variables override
$table-border-color: $color-border;


/************************* BUTTONS ***********************************/
//  Bootstrap variables override
// don't add css variables to bootstrap buttons, because bootstrap use color functions on it
$btn-default-color: $gray-darker;
$btn-default-bg: $color-white;
$btn-default-border: #E0E0E4;  //can't be css variable

$btn-primary-color: $color-white;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);

$btn-success-color: $color-white;
$btn-success-bg:  $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);

$btn-info-color:$color-white;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);

$btn-warning-color: $color-white;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);

$btn-danger-color: $color-white;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);

$btn-link-disabled-color: $gray-light;
  
/************************* ALERTS ***********************************/
//  Bootstrap variables override

$alert-padding:               20px;
$alert-border-radius:         $border-radius-base;
$alert-link-font-weight:      bold ;

$alert-success-bg:            $color-success-light;
$alert-success-text:          #096651; //can't be css variable
$alert-success-border:        #67b7a5; //can't be css variable

$alert-info-bg:               $color-info-light;
$alert-info-text:             #1f62af; //can't be css variable
$alert-info-border:           #bbcde6; //can't be css variable

$alert-warning-bg:            $color-warning-light;
$alert-warning-text:          #856404; //can't be css variable
$alert-warning-border:        #f0d171; //can't be css variable

$alert-danger-bg:             $color-danger-light;
$alert-danger-text:           #970d20; //can't be css variable
$alert-danger-border:         #e78d99; //can't be css variable

/************************* INPUTS ***********************************/
//  Bootstrap variables override
$input-border-focus: darken($btn-default-border, 20%);
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2));


/************************* DROPDOWNS ***********************************/
//  Bootstrap variables override
$dropdown-bg: white; //can't be css variable
$dropdown-link-color: $color-secondary;
$dropdown-link-hover-color: $color-dark;

$dropdown-font-size: var(--dropdown-font-size, 14px);

/************************* PANELS ***********************************/
//  Bootstrap variables override
//$panel-default-border: $input-border-color;
$panel-default-text: $color-dark;
$panel-default-heading-bg: $color-dark;

/************************* Z-INDEX **************************************/
// Bootstrap z-index variables: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

// Custom z-index variables
$zindex-breadcrumb: 1000;
$zindex-toolbar: 1005;
$zindex-page-holder: 50;
$zindex-clipboard-sidebar: 30;
$zindex-input-element: 10;
$zindex-subtabs: 20;
$zindex-footer: 40;
$zindex-yasqe-autocomplete: 1050;
$zindex-cytoscape-extensions: 999;
$zindex-notifications: 1080;
$zindex-select-menu: 1010;
$zindex-yasqe-fullscreen: 1040;


/************************* NAVBAR **************************************/
//  Bootstrap variables override
$navbar-default-bg: #242426; //can't be css variable
$navbar-default-link-hover-color: #fff;
$navbar-default-link-hover-bg: $color-dark-darker;


/************************* PROGRESS BAR **************************************/
$progress-bar-bg: var(--progress-bar-bg, $color-secondary-light);
$progress-bar-success-bg: --color-success;
$progress-bar-info-bg: --color-info;
$progress-bar-warning-bg: --color-warning;
$progress-bar-danger-bg: --color-danger;


/************************* PAGINATION **************************************/
//  Bootstrap variables override
$pagination-color: $color-dark;
$pagination-bg: transparent;
$pagination-border: transparent;

$pagination-hover-color: $color-dark;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: transparent;

$pagination-active-color: #fff;
$pagination-active-bg: $color-dark;
$pagination-active-border: transparent;

$pagination-disabled-color: $color-dark;
$pagination-disabled-bg: transparent;
$pagination-disabled-border: transparent;

/************************* FORMS ******************************************/
$input-height: var(--input-height, 38px);

$input-color: var(--input-color, $color-dark);
$input-color-bg: var(--input-color-bg, #ffffff);
$input-font-size: var(--input-font-size, 14px);
$input-placeholder-color: var(--input-placeholder-color, $color-base);
$input-border-color: var(--input-border-color, $color-border);
$input-border-radius: var(--input-border-radius, 2px);
$input-border-radius-large: var(--input-border-radius, 2px);
$input-border-radius-small: var(--input-border-radius, 2px);


$input-focused-color: var(--input-focused-color, #05448d);
$input-focused-color-bg: var(--input-focused-color-bg, #d9e2f0);
$input-focused-border-color: var(--input-focused-border-color, #aec4de);

$color-select-value-bg: var(--color-select-value-bg, $color-light);
$color-checkbox-select: var(--color-checkbox-select, $color-action);

/* override react-joyride styling */
$joyride-color: $brand-danger;

/** page dimension overrides **/
$rs-dims-footer-height: 180px;

/* react-select variables override  
node_modules/react-select/scss/select.scss
*/
// control options
//$select-input-bg:                   #fff; //can't be css a variable
//$select-input-bg-disabled:          #f9f9f9;
//$select-input-border-radius:        $border-radius-base;
//$select-input-border-color:         #1d096e17;
//$select-input-border-width:         1px;
//$select-input-height:               $input-height;
//$select-input-placeholder:          $color-dark;
$select-text-color:                 $color-dark;
$select-input-hover-box-shadow:     0 1px 0 rgba(0, 0, 0, 0.06);

// menu options
$select-menu-box-shadow:            $select-input-hover-box-shadow;

$select-option-color:               $color-dark;
$select-option-bg:                  #ffffff;
$select-option-focused-color:       $color-dark;
$select-option-focused-bg:          $color-lighter;
$select-option-selected-color:      $color-dark;
$select-option-selected-bg:         $color-light;
$select-option-disabled-color:      $color-dark--op50;

$select-noresults-color:            $color-dark--op30;

// clear "x" button
$select-clear-color:                $color-dark;
$select-clear-hover-color:          $color-dark; // red

// arrow indicator
$select-arrow-color:                $color-dark;
$select-arrow-color-hover:          $color-dark;

// loading indicator
$select-loading-color-bg:           #E0E0E4;

// multi-select item
$select-item-border-radius:         2px;
$select-item-font-size:             $font-size-base;
$select-item-color:                 $color-white; 
$select-item-bg:                    $color-dark;
$select-item-border-color:          $color-dark;
$select-item-hover-color:           $color-dark;
$select-item-hover-bg:              $color-white;
$select-item-disabled-color:        $color-dark--op50;
$select-item-disabled-bg:           $color-light;
$select-item-disabled-border-color: $color-light;

// react-select
$select-menu-max-height: 210px;




/* key page element dimensions */
$breadcrumb-height: var(--breadcrumb-height, 38px);
$nav-height: var(--nav-height, 63px);

$pageSection-bg: var(--pageSection-bg, $color-lighter);
