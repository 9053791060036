/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 
/* Admin Pages */
.adminPage-header-container {

  .adminPage-header--col {
    padding-top: 2.8%;
    padding-bottom: 1.3%;
  }

  .adminPage-header--content {

    display: flex;
    align-items: center;
  }

  .adminPage-header--thumbnail {

    padding-right: 15px;

    & .fa,
    & .rs-icon,
    .material-icons-round,
    .material-symbols-rounded {
      font-size: 45px;

      color: $color-dark;

      @media (min-width: 2000px) {

        font-size: 2.8em;
      }
    }
  }

  .adminPage-header--body{

    width: 100%;

    & h2 {
      font-size: 23px;
      border-bottom: 1px solid $color-dark;
      padding-bottom: 4px;
      padding-left: 8px;

    }
  }
}

/* Admin Main page rsp:admin */
.admin-title {
  margin-top: 20px;
  font-size: 17px;
  letter-spacing: -0.2px;
}

.admin-icon-panel {

  margin-right: 20px;
  font-size: 13px;

  width: 97%;
  height: 160px;

  cursor: pointer;
  border-color: $color-border;

  & .panel-body {
    background-color: $color-light;
    padding: 10px;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    & .fa,
    & .rs-icon,
    & .iconmoon {

      font-size: 45px;
    }

    .material-icons-round,
    .material-symbols-rounded {

      font-size: 50px;
    }
  }

  & .panel-footer {
    background-color: $color-white;
    border-top: 1px solid $color-border;
    height: 25%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  }

}

.admin-system-manag_btnContainer {

  display: flex;
  flex-direction: column;
  gap: 15px;

  .btn {

    width: 100%;
  }

  .btn-textAndIcon {

    align-self: stretch;
  }

  .restart-no-permission {

    display: flex; 
    align-items: center;
    color: $color-danger-text;

    cursor: not-allowed;
    opacity: 0.6;

  }

  .alert-component {

    font-size: $font-size-base;
    overflow-wrap: anywhere;
    margin-bottom: 0;
  }
}

.invalidate-cache-container {

  display: flex;
  align-items: stretch;
  gap: 5px;

  margin-top: 5px;
}

/* Ontology page */
.rs-ontologies {

  padding-bottom: 40px;

  .ontology-upload,
  .ontology-search {

    cursor: pointer;
    font-size: $font-size-large;
    margin-right: 30px;

    .fa {

      padding-right: 10px;
    }
  }

  .badge {
    padding: 0 15px;
    height: 28px;
  }

  .rs-ontology-icon {
    display: flex;
    cursor: pointer;

    &:hover {
      background-color: $color-lighter;

      button {
        background-color: $color-lighter;
      }
    }

    button {
      border: none;
    }

    img {
      height: 28px;
      width: 32px;
    }
  }
}

.rs-fields {

  .researchspace-table-widget-holder .griddle-filter .filter-container {

    justify-content: flex-start;
  }
}

/* Asset page */
.asset-uploads {

  .asset-uploads-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .asset-upload-form-types {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;

    button {
      margin-right: 7px;
    } 
  }

  .asset-upload-form {

    overflow-y: auto;
    padding-right: 10px;
    height: 100%;
  }

}

.delete-account-btn {

  display: flex;
  justify-content: end;
  margin-top: 5px;
}