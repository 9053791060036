/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/* Tree View component style */
.tree-view {

  &_item {

    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 8px;

    .tree-view_arrow {

      margin: 0;
    }

    .Tree--treeNode {
      .Tree--itemLink {
        margin: 0;
      }
    }

    .Tree--leafNode {
      margin: 0;

      .Tree--itemLink {

        margin: 0;
      }
    }
  }

  .tree-view_arrow-collapsed {

    margin: 0;
  }

  .tree-view_children-collapsed {
    margin: 0;
  }

  &_children {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &_children {

    & .Tree--leafNode {
      margin-bottom: 4px;
    }
  }

  &_arrow {
      float: left;
   //   margin-right: 10px !important;

      &:after {

        color: $color-secondary;
    }
  }
}

.Tree--leafNode {
    margin-bottom: 8px;
}

.Tree--treeNode,
.Tree--leafNode {


  & a,
  & .Tree--itemLink {

    color: $color-secondary;
    font-size: 14px;
    font-weight: 500;

    cursor: pointer;
    text-transform: capitalize;
    min-height: inherit;
  
    display: flex; 
    align-items: center; 
    gap: 5px;
    width: fit-content;
  //  margin-bottom: 7px;
  
    i {
      color: $color-secondary;
      font-size: 17.5px;
    }
  
    &.active {
  
      cursor: auto;
      color: $color-action;
  
      i {
        color: $color-action;
      }
    }
  }

  & .Tree--itemLink {
  //  margin-bottom: 8px;
  }
}

.tree-tableView {

  .tree-view {

    &_item {
  
      margin-bottom: 4px;
      padding: 6px;
      border-radius: $border-radius;
      border: 1px solid $color-border-darker;
      background-color: $color-lighter-hover;
    }

    &_children {

      & .Tree--leafNode {

        margin-bottom: 0;

        .Tree--itemLink {

          margin: 0;
        }
      }
  
      & > .Tree--leafNode {

        margin-bottom: 4px;
        padding: 6px 10px;
        border-radius: $border-radius;
        border: 1px solid $color-border-darker;
        background-color: $color-lighter-hover;
      }
    }
  }
}